<form [formGroup]="form" (ngSubmit)="submit()">
<div class="grid-container">
  <div class="grid-card-left">
    @if(config.image){
    <img class="grid-card-img" src="{{config.image}}" style="" />
  }
</div>
  <div class="grid-card-right">

<h3 class="grid-container-header" [innerHTML]="config.title"></h3>
<sps-sanitized-html [html]="config.description"/>
<div class="grid-container-content-footer">
<sps-button-link (click)="hideWebinarDialog()" [href]="config.cta.link.href" [label]="config.cta.link.label"/>
</div>            
</div>
  <div class="grid-card-footer">
      <label>
          <input  
           (click)="onCheckboxChange($event)" #input type="checkbox"/>
       
         
          <span> {{ "GENERAL.WEBINAR.DO_NOT_SHOW_TEXT" | translate }}</span>
      </label>
  </div>
</div>
</form>
